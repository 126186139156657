import React from "react";
import {
    Stack,
    Alert,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    AlertColor,
} from "@mui/material";
import {
    useGetSessionSetUpAssessmentsByEventKeyQuery,
    useLazyGetAIKeybyDocKeyQuery,
    useSaveAssessmentListMutation,
} from "../../../../../services/cclTokenedSessionApi";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import {
    AssessmentListItem,
    AssessmentList,
    DocumentList,
    AddAssessmentListRequest,
} from "../../../../../services/types/sessionDetailTypes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddAssessmentDrawer from "./addAssessmentDrawer";
import { AssetDownloadRequest } from "../../../../../services/types/assetApiTypes";
import SessionAssessmentsSetupTable from "./sessionAssessmentsSetupTable";
import AddDocumentDrawer from "./addDocumentDrawer";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";

export interface SessionAssessmentSetupAccordionProps {
    esKey: number;
    projectCode: string;
    hasAssessmentPlatform: boolean;
    readOnly?: boolean;
    showSnackbar: (message: string, severity: AlertColor) => void;
}

export const SessionAssessmentSetupAccordion: React.FC<SessionAssessmentSetupAccordionProps> = (
    props
) => {
    const downloadService = new FileDownloadService();
    const [assessmentList, setAssessmentList] = React.useState<AssessmentListItem[]>([]);
    const [assessmentOptionsList, setAssessmentOptionsList] = React.useState<AssessmentList[]>([]);
    const [documentOptionsList, setDocumentOptionsList] = React.useState<DocumentList[]>([]);
    const [showAddAssessmentDialog, setShowAddAssessmentDialog] = React.useState<boolean>(false);
    const [showAddDocumentDialog, setShowAddDocumentDialog] = React.useState<boolean>(false);
    const [enableSave, setEnableSave] = React.useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [getDocsAiKey] = useLazyGetAIKeybyDocKeyQuery();
    const {
        data: sessionAssessments,
        isSuccess: assessmentsSuccess,
        isLoading: assessmentsLoading,
    } = useGetSessionSetUpAssessmentsByEventKeyQuery(props.esKey.toString(), {
        skip: !props.esKey,
    });
    const [saveAssessmentList] = useSaveAssessmentListMutation();
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        setAssessmentList(sessionAssessments?.addedAssessmentList ?? []);
        setAssessmentOptionsList(sessionAssessments?.assessmentOptionsList ?? []);
        setDocumentOptionsList(sessionAssessments?.documentOptionsList ?? []);
    }, [sessionAssessments]);

    const RemoveAssessments = (cclMasterKeys: number[]) => {
        let newAssessmentList: AssessmentListItem[] = [];
        assessmentList.forEach((a) => {
            if (!cclMasterKeys.includes(a.cclMasterKey)) {
                newAssessmentList.push(a);
            }
        });
        setAssessmentList(newAssessmentList);
        setEnableSave(true);
    };

    const AddAssessmentItem = (newItem: AssessmentListItem) => {
        if (newItem == null) return;

        let found = assessmentList.find((a) => a.cclMasterKey === newItem.cclMasterKey);
        if (found)
            // don't need to add if we already found it in our current list
            return;

        let newAssessmentList = [...assessmentList];
        newAssessmentList.push(newItem);
        setAssessmentList(newAssessmentList);
        setEnableSave(true);
    };

    const viewDocument = (docId: number) => {
        getDocsAiKey(docId)
            .unwrap()
            .then((aidata) => {
                if (aidata && aidata?.aI_Key > 0) {
                    let doc = documentOptionsList.filter((d) => d.documentID === docId);
                    if (doc && doc.length > 0) {
                        let request: AssetDownloadRequest = {
                            fname: doc[0].documentName,
                            aikeys: [aidata.aI_Key],
                            flatzip: false,
                        };
                        downloadService.DownloadAssets(request).then(() => {});
                    }
                } else {
                    //SHOW ERROR
                }
            });
    };

    const handleEvent = (evt: string, ids: number[]) => {
        switch (evt) {
            case "addAssessment":
                setShowAddAssessmentDialog(true);
                break;

            case "addDocument":
                setShowAddDocumentDialog(true);
                break;

            case "view":
                if (ids.length === 1) viewDocument(ids[0]);
                break;

            case "remove":
                if (ids.length > 0) RemoveAssessments(ids);
                break;
        }
    };

    const saveChanges = () => {
        let request: AddAssessmentListRequest = {
            esKey: props.esKey,
            assessmentList: [...assessmentList],
        };
        saveAssessmentList(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.projectCode,
                };
                logEvent("SessionSetupSaved", evtData);
                props.showSnackbar("Assessments & Documents Updated", "success");
                setEnableSave(false);
            })
            .catch(() => {
                setEnableSave(false);
                props.showSnackbar("Assessments & Documents Update Failed", "error");
            });
    };

    return (
        <Accordion sx={{ h: 1 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: "100%", marginLeft: "15px", fontWeight: "bolder" }}>
                    Assessments and Documents
                </Typography>
                {!expanded && enableSave ? (
                    <Box sx={{ width: "100%", float: "right", marginTop: "12px !important" }}>
                        <Stack direction={"row"} spacing={2} sx={{ float: "right" }}>
                            <Alert severity="info">
                                You have unsaved changes to the session's assessments and document
                                list.
                            </Alert>
                            <CclLoadingButton
                                size="small"
                                sx={{ float: "right", marginRight: "15px", height: 36 }}
                                disabled={!enableSave}
                                onClick={saveChanges}
                                mode={"primary"}
                                loading={assessmentsLoading}
                            >
                                Save
                            </CclLoadingButton>
                        </Stack>
                    </Box>
                ) : null}
            </AccordionSummary>
            <AccordionDetails>
                {assessmentsSuccess || assessmentsLoading ? (
                    <Stack width={1} spacing={2} padding={3}>
                        {props.hasAssessmentPlatform ? (
                            <React.Fragment>
                                <AddAssessmentDrawer
                                    open={showAddAssessmentDialog}
                                    esKey={props.esKey}
                                    onCancel={() => setShowAddAssessmentDialog(false)}
                                    onAdd={AddAssessmentItem}
                                    assessmentOptionsList={assessmentOptionsList}
                                    showSnackbar={props.showSnackbar}
                                />
                                <AddDocumentDrawer
                                    open={showAddDocumentDialog}
                                    esKey={props.esKey}
                                    onCancel={() => setShowAddDocumentDialog(false)}
                                    onAdd={AddAssessmentItem}
                                    onViewDocument={viewDocument}
                                    documentOptionsList={documentOptionsList}
                                    showSnackbar={props.showSnackbar}
                                />
                                <SessionAssessmentsSetupTable
                                    AssessmentList={assessmentList}
                                    isLoading={assessmentsLoading}
                                    handleEvent={handleEvent}
                                    readOnly={props.readOnly}
                                />
                                {props.readOnly ? null : (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            float: "right",
                                            marginTop: "12px !important",
                                        }}
                                    >
                                        <Stack direction="row" spacing={2} sx={{ float: "right" }}>
                                            {enableSave ? (
                                                <Alert severity="info">
                                                    You have unsaved changes to the session's
                                                    assessments and document list.
                                                </Alert>
                                            ) : null}
                                            <CclLoadingButton
                                                size="small"
                                                sx={{
                                                    float: "right",
                                                    marginRight: "15px",
                                                    height: 36,
                                                }}
                                                disabled={!enableSave}
                                                onClick={saveChanges}
                                                mode={"primary"}
                                                loading={assessmentsLoading}
                                            >
                                                Save
                                            </CclLoadingButton>
                                        </Stack>
                                    </Box>
                                )}
                            </React.Fragment>
                        ) : (
                            <Alert severity="warning" sx={{ width: "100%" }}>
                                An Assessment Platform has not been specified in D365 and therefore
                                this feature is unavailable at this time. Please specify an
                                Assessment Platform in D365 to gain access to this feature.
                            </Alert>
                        )}
                    </Stack>
                ) : (
                    <Alert severity={"error"} sx={{ width: "100%" }}>
                        There was an error retrieving Assessments and Documents for this session
                    </Alert>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
export default SessionAssessmentSetupAccordion;
