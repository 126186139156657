import * as React from "react";
import { Box, Stack } from "@mui/material";
import ResourcesDataGridPro from "./resourceList";
import AdvancedSearchControl from "./advancedSearchControl";
import { useLazyGetAllResourcesQuery } from "../../../services/cclTokenedResourceApi";
import CclSearchDefault from "../../../components/common/cclSearchDefault";
import CclButton from "../../../components/common/cclButtons/cclButton";
import CclDoubleTextSearchBar from "../../../components/common/cclLandingPageSearchBars/cclDoubleTextSearchBar";
import CclTextSearchBar from "../../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSearchParams } from "../../../app/slices/resourceListConfigSlice";

interface CclResourcesTabPanelProps {}

const CclResourcesTabPanel: React.FC<CclResourcesTabPanelProps> = (props) => {
    const searchSelector = useSelector((state: any) => state.resourceListConfig);
    const dispatch = useDispatch();

    const [searchEmail, setSearchEmail] = React.useState<string>(searchSelector.Email);
    const [searchFirstName, setSearchFirstName] = React.useState<string>(searchSelector.FirstName);
    const [searchLastName, setSearchLastName] = React.useState<string>(searchSelector.LastName);

    const [getAll, { data: resources, isLoading }] = useLazyGetAllResourcesQuery();
    const [advancedSearchResults, setAdvancedSearchResults] = React.useState<number[] | undefined>(
        undefined
    );

    const onAdvancedSearchResults = (pKeys: number[] | undefined) => {
        setSearchEmail("");
        setSearchFirstName("");
        setSearchLastName("");
        dispatch(
            updateResourceSearchParams({
                email: null,
                firstName: null,
                lastName: null,
            })
        );
        setAdvancedSearchResults(pKeys);
        if (!resources || resources.length === 0) {
            getAll();
        }
    };

    const filteredResources = () => {
        if (!resources || resources.length === 0) {
            return [];
        } else if (advancedSearchResults) {
            return resources?.filter((x) => advancedSearchResults.includes(x.pkey));
        } else if (searchSelector.Email !== null) {
            return resources?.filter(
                (x) =>
                    x.email != null &&
                    x.email.localeCompare(searchSelector.Email, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.FirstName !== null && searchSelector.LastName !== null) {
            return resources?.filter(
                (x) =>
                    x.firstname != null &&
                    x.lastname != null &&
                    x.firstname.localeCompare(searchSelector.FirstName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0 &&
                    x.lastname.localeCompare(searchSelector.LastName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.FirstName) {
            return resources?.filter(
                (x) =>
                    x.firstname != null &&
                    x.firstname.localeCompare(searchSelector.FirstName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.LastName) {
            return resources?.filter(
                (x) =>
                    x.lastname != null &&
                    x.lastname.localeCompare(searchSelector.LastName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else {
            return resources;
        }
    };

    const handleGetAll = () => {
        setSearchEmail("");
        setSearchFirstName("");
        setSearchLastName("");
        setAdvancedSearchResults(undefined);
        dispatch(
            updateResourceSearchParams({
                email: null,
                firstName: null,
                lastName: null,
            })
        );
        getAll();
    };

    const getResourceByEmail = (email: string) => {
        setSearchEmail(email);
        setSearchFirstName("");
        setSearchLastName("");
        dispatch(
            updateResourceSearchParams({
                email: email,
                firstName: null,
                lastName: null,
            })
        );
        setAdvancedSearchResults(undefined);
        if (!resources || resources.length === 0) {
            getAll();
        }
    };

    const getResourcesByName = (firstName: string, lastName: string) => {
        setSearchEmail("");
        setSearchFirstName(firstName);
        setSearchLastName(lastName);
        dispatch(
            updateResourceSearchParams({
                email: null,
                firstName: firstName === "" ? null : firstName,
                lastName: lastName === "" ? null : lastName,
            })
        );
        setAdvancedSearchResults(undefined);
        if (!resources || resources.length === 0) {
            getAll();
        }
    };

    return (
        <Stack spacing={2} width={1} direction={"column"}>
            <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                <Box sx={{ flex: "1", paddingRight: 1 }}>
                    <CclTextSearchBar
                        initialSearchTerm={searchEmail}
                        searchLabel="Enter Resource Email"
                        executeSearch={getResourceByEmail}
                        removeLeftPadding={true}
                    />
                </Box>
                <Box sx={{ flex: "1", paddingRight: 1, paddingLeft: 1 }}>
                    <CclDoubleTextSearchBar
                        executeSearch={getResourcesByName}
                        initialFieldOneSearchTerm={searchFirstName}
                        fieldOneSearchLabel={"Enter First Name"}
                        initialFieldTwoSearchTerm={searchLastName}
                        fieldTwoSearchLabel={"Enter Last Name"}
                        requireBothFields={false}
                        suppressErrors={true}
                    />
                </Box>

                <Box
                    sx={{
                        flexShrink: 0,
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 1,
                        paddingRight: 1,
                        float: "right",
                    }}
                >
                    <CclButton
                        onClick={handleGetAll}
                        sx={{
                            float: "right",
                            flexShrink: 0,
                            whiteSpace: "nowrap",
                        }}
                        mode={"primary"}
                    >
                        Get All
                    </CclButton>
                </Box>
            </Box>
            <AdvancedSearchControl onSearchResults={onAdvancedSearchResults} />
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {isLoading || resources !== undefined ? (
                    <ResourcesDataGridPro loading={isLoading} resources={filteredResources()} />
                ) : (
                    <CclSearchDefault
                        line1={"Start searching for Resources"}
                        line2={
                            "Enter Resource Email or First and Last Name and click Search or click Get All or expand Advanced Search"
                        }
                    />
                )}
            </Box>
        </Stack>
    );
};

export default CclResourcesTabPanel;
