import React from "react";
import { Box, TextField } from "@mui/material";
import CclButton from "../cclButtons/cclButton";

interface CclTextSearchBarProps {
    initialSearchTerm: string;
    searchLabel: string;
    executeSearch: (searchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
    removeLeftPadding?: boolean;
}

const CclTextSearchBar: React.FC<CclTextSearchBarProps> = (props) => {
    const [searchString, setSearchString] = React.useState<string>(props.initialSearchTerm);
    const [keywordSearchError, setKeywordSearchError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSearchString(props.initialSearchTerm);
    }, [props.initialSearchTerm]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
        flexGrow: 1,
    };

    const setSearchError = (error: boolean) => {
        if (error !== keywordSearchError) {
            setKeywordSearchError(error);
            if (props.errorStateChange) props.errorStateChange(error);
        }
    };

    const handleKeywordSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (!keywordSearchError && searchString !== "") {
                evt.preventDefault();
                props.executeSearch(searchString);
            }
        }
    };

    return (
        <Box
            gap={2}
            sx={{
                pt: 2,
                pb: 2,
                pr: 2,
                pl: props.removeLeftPadding ? 0 : 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <TextField
                value={searchString === "*" ? "" : searchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search"
                label={props.searchLabel ?? "Search Term"}
                variant="outlined"
                error={keywordSearchError}
                fullWidth={true}
                helperText={keywordSearchError ? "Field cannot be empty" : ""}
                onChange={(e) => {
                    e.preventDefault();
                    setSearchString(e.target.value);
                    setSearchError(e.target.value === "");
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
            />
            <CclButton
                onClick={() => props.executeSearch(searchString.trim())}
                aria-label="Search By ID or Keyword"
                sx={{ flexShrink: 0, whiteSpace: "nowrap" }}
                disabled={keywordSearchError || searchString === ""}
                mode={"primary"}
            >
                Search
            </CclButton>
        </Box>
    );
};

export default CclTextSearchBar;
