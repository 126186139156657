import * as React from "react";
import { Alert, AlertTitle, Box, Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMsg } from "../../utilities/rtkQueryErrorHelpers";
import { updateNotificationSearchParams } from "../../app/slices/notificationListConfigSlice";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { useGetCclNotificationsMutation } from "../../services/cclTokenedNotificationApi";
import { NotificationsDataGridPro } from "./notificationDataGrid";
import CclDateWithTextSearchBar from "./cclDateWithTextSearchBar";
import ComponentLoader from "../../components/common/componentLoader";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import CclButton from "../../components/common/cclButtons/cclButton";
import { useNavigate } from "react-router-dom";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";

export const Notifications: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.notificationListConfig);

    const [searchString, setSearchString] = useState<string>(config.Keyword);
    const [showSearchToBroad, setShowSearchToBroad] = useState<boolean>(false);
    let claimservice = new UserClaimsService();
    let navigate = useNavigate();

    const [runSearch, { data, isLoading, isSuccess, isError, error }] =
        useGetCclNotificationsMutation();

    React.useLayoutEffect(() => {
        if (isSuccess && data && data.length >= 30000) setShowSearchToBroad(true);
        // eslint-disable-next-line
    }, [data]);

    const getNotifications = async (
        startDt: Date | null,
        endDt: Date | null,
        searchTerm: string
    ) => {
        if (startDt == null || endDt == null) {
            return;
        }

        let keyword = searchTerm == null || searchTerm === "" ? "*" : searchTerm.trim();
        let start = new Date(startDt.setHours(0o0, 0o0, 0o0, 0o0)).toISOString();
        let end = new Date(endDt.setHours(23, 59, 59, 999)).toISOString();
        setSearchString(searchTerm);

        runSearch({
            keyword: keyword,
            start: start,
            end: end,
        });

        dispatch(
            updateNotificationSearchParams({
                StartDate: start,
                EndDate: end,
                Keyword: keyword,
            })
        );
    };

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error));
    }

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Emails
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                <CclErrorDialog
                    open={showSearchToBroad}
                    title={"Too Many Results"}
                    msg={
                        "There are too many results for this search. Consider adjusting your search parameters and searching again."
                    }
                    onOk={() => setShowSearchToBroad(false)}
                />
                <Card
                    sx={{
                        width: 1,
                        height: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <CclDateWithTextSearchBar
                            executeSearch={getNotifications}
                            initialSearchTerm={searchString}
                            initialStartDate={null}
                            initialEndDate={null}
                            startDateLabel={"From Date"}
                            endDateLabel={"To Date"}
                            searchTermLabel={"Enter User Email (optional)"}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {isLoading ? (
                            // search, or loading in progress
                            <ComponentLoader msg={"Loading"} />
                        ) : isSuccess || data ? (
                            <NotificationsDataGridPro
                                notifications={data}
                                isLoading={isLoading}
                                showResultBar={false}
                                resultBarText={""}
                            />
                        ) : (
                            <CclSearchDefault
                                line1="Start searching for Emails"
                                line2="Enter a Date Range and click Search or optionally also include a User Email"
                                hasButtons={
                                    claimservice.IsUserInAppRole(8) ||
                                    claimservice.IsUserSuperAdmin()
                                }
                            >
                                <CclButton
                                    mode={"primary"}
                                    onClick={() => navigate("/emails/templates")}
                                >
                                    View Templates
                                </CclButton>
                            </CclSearchDefault>
                        )}
                    </Box>
                </Card>
            </Stack>
        </StandardLayout>
    );
};

export default Notifications;
