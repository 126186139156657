import * as React from "react";
import {
    AlertColor,
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import {
    useAddPartnerAdminMutation,
    useAddPartnerCoachMutation,
    useGetPartnerGroupsQuery,
    useUpdatePartnerAdminMutation,
    useUpdatePartnerCoachMutation,
} from "../../../services/cclTokenedEnterpriseNetworkPartnerApi";
import {
    PartnerGroupPerson,
    UpdatePartnerGroupAdminPayload,
    UpdatePartnerGroupCoachPayload,
} from "../../../services/types/enterpriseNetworkPartnerTypes";
import { AccessEventPartnerIndividualDetails } from "../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclDrawerActionBar from "../../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../components/common/cclButtons/cclLoadingButton";
import CclDrawer from "../../../components/common/cclDrawer/cclDrawer";
import { useLazyGetCclUserAccountDetailQuery } from "../../../services/cclTokenedGrandCentralApi";
import { validateEmail } from "../../../utilities/generalUtilities";

export interface EditPartnerResourceDrawerProps {
    open: boolean;
    userPartnerKey: number | null;
    resource: PartnerGroupPerson | null;
    handleClose: () => void;
    showSnackbar: (message: string, severity: AlertColor) => void;
}

export const EditPartnerResourceDrawer: React.FC<EditPartnerResourceDrawerProps> = (props) => {
    const { data: partnerGroups } = useGetPartnerGroupsQuery();
    const [addAdmin, { isLoading: addAdminIsLoading }] = useAddPartnerAdminMutation();
    const [updateAdmin, { isLoading: updateAdminIsLoading }] = useUpdatePartnerAdminMutation();
    const [addCoach, { isLoading: addCoachIsLoading }] = useAddPartnerCoachMutation();
    const [updateCoach, { isLoading: updateCoachIsLoading }] = useUpdatePartnerCoachMutation();
    const [getExistingUserDetail] = useLazyGetCclUserAccountDetailQuery();
    const { logEvent } = useLogAccessEvent();

    const [isNew, setIsNew] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>(props.resource?.firstName ?? "");
    const [lastName, setLastName] = React.useState<string>(props.resource?.lastName ?? "");
    const [email, setEmail] = React.useState<string>(props.resource?.email ?? "");
    const [emailInputError, setEmailInputError] = React.useState<string>("");
    const [isCoach, setIsCoach] = React.useState<boolean>(props.resource?.isCoach ?? false);
    const [isActive, setIsActive] = React.useState<boolean>(props.resource?.active ?? false);
    const [isAdmin, setIsAdmin] = React.useState<boolean>(props.resource?.isAdmin ?? false);
    const [partnerOptions, setPartnerOptions] = React.useState<{ val: string; option: string }[]>(
        []
    );
    const [selectedPartner, setSelectedPartner] = React.useState<{
        val: string;
        option: string;
    } | null>();

    React.useEffect(() => {
        // clear out the state on open if new. if edit, it will be taken care of
        if (props.open === false && props.resource === null) {
            setFirstName("");
            setLastName("");
            setEmail("");
            setIsCoach(false);
            setIsActive(false);
            setIsAdmin(false);
            if (props.userPartnerKey == null) {
                setSelectedPartner(null);
            }
            setEmailInputError("");
        }
        // eslint-disable-next-line
    }, [props.open]);

    const loadSelectedPartner = (opts: { val: string; option: string }[]) => {
        if (opts == null || opts.length <= 0) return;

        const keytofind =
            props.userPartnerKey != null
                ? props.userPartnerKey
                : props.resource != null
                ? props.resource.pgKey
                : -1;

        setSelectedPartner(opts.find((o) => o.val === keytofind.toString()) ?? null);
    };

    React.useEffect(() => {
        setIsNew(props.resource == null);
        setTitle(props.resource == null ? "Add New Resource" : "Edit Resource Information");
        setFirstName(props.resource?.firstName ?? "");
        setLastName(props.resource?.lastName ?? "");
        setEmail(props.resource?.email ?? "");
        setIsCoach(props.resource?.isCoach ?? false);
        setIsActive(props.resource?.active ?? false);
        setIsAdmin(props.resource?.isAdmin ?? false);
        setEmailInputError("");
        loadSelectedPartner(partnerOptions);
        // eslint-disable-next-line
    }, [props.resource]);

    const anyChanges = (): boolean => {
        return (
            firstName !== props.resource?.firstName ||
            lastName !== props.resource?.lastName ||
            isActive !== props.resource?.active ||
            isAdmin !== props.resource?.isAdmin ||
            isCoach !== props.resource?.isCoach
        );
    };

    React.useEffect(() => {
        if (partnerGroups == null || partnerGroups.length <= 0) return;

        const opts = partnerGroups
            .filter((pg) => pg.visibleInDst)
            .map((pg) => {
                return { val: pg.partnerGroupKey.toString(), option: pg.description };
            });
        setPartnerOptions(opts);
        loadSelectedPartner(opts);
        // eslint-disable-next-line
    }, [partnerGroups]);

    const addPartnerResource = (existingImKey: number | null) => {
        if (selectedPartner == null) return;

        if (!validateEmail(email)) {
            setEmailInputError("Invalid Email Format");
            return;
        }
        const coachPayload: UpdatePartnerGroupCoachPayload = {
            partnerGroupCoachKey: null,
            partnerGroupKey: +selectedPartner.val,
            imKey: existingImKey,
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            active: true,
        };
        addCoach(coachPayload)
            .unwrap()
            .then((result) => {
                const evtData: AccessEventPartnerIndividualDetails = {
                    email: coachPayload.emailAddress,
                    partner: selectedPartner.option,
                    imKey: result.imKey.toString(),
                };
                logEvent("PartnerResourceAdded", evtData);
                props.showSnackbar("Partner Resource Added", "success");
                props.handleClose();
            })
            .catch(() => {
                props.showSnackbar("Partner Resource Addition Failed", "error");
                props.handleClose();
            });
    };

    const addResource = () => {
        if (selectedPartner == null) return;

        if (!validateEmail(email)) {
            setEmailInputError("Invalid Email Format");
            return;
        }

        getExistingUserDetail(email)
            .unwrap()
            .then((result) => {
                const existingImKey = result?.length === 1 ? result[0].imKey : null;

                // create admin first, in case both admin and resource need to be created
                if (isAdmin) {
                    const adminPayload: UpdatePartnerGroupAdminPayload = {
                        partnerGroupIndividualKey: null,
                        partnerGroupKey: +selectedPartner.val,
                        imKey: existingImKey,
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: email,
                    };
                    addAdmin(adminPayload)
                        .unwrap()
                        .then((result) => {
                            const evtData: AccessEventPartnerIndividualDetails = {
                                email: adminPayload.emailAddress,
                                partner: selectedPartner.option,
                                imKey: result.imKey.toString(),
                            };

                            logEvent("PartnerResourceAdded", evtData);

                            if (isCoach) {
                                addPartnerResource(result.imKey);
                            } else {
                                props.showSnackbar("Partner Resource Added", "success");
                                props.handleClose();
                            }
                        })
                        .catch(() => {
                            props.showSnackbar("Partner Resource Addition Failed", "error");
                            props.handleClose();
                        });
                } else if (isCoach) {
                    addPartnerResource(existingImKey);
                }
            });
    };

    const updateResource = () => {
        if (selectedPartner == null || !anyChanges()) return;

        let coachPayload: UpdatePartnerGroupCoachPayload = {
            partnerGroupCoachKey: null,
            partnerGroupKey: +selectedPartner.val,
            imKey: props.resource?.imKey ?? null,
            firstName: firstName,
            lastName: lastName,
            emailAddress: props.resource?.email ?? "",
            active: isActive,
        };

        const adminPayload: UpdatePartnerGroupAdminPayload = {
            partnerGroupIndividualKey: null,
            partnerGroupKey: +selectedPartner.val,
            imKey: props.resource?.imKey ?? null,
            firstName: firstName,
            lastName: lastName,
            emailAddress: props.resource?.email ?? "",
        };

        if (props.resource?.isCoach) {
            if (props.resource?.firstName || props.resource?.lastName || props.resource?.active) {
                updateCoach(coachPayload)
                    .unwrap()
                    .then((result) => {
                        const evtData: AccessEventPartnerIndividualDetails = {
                            email: coachPayload.emailAddress,
                            partner: selectedPartner.option,
                            imKey: result.imKey.toString(),
                        };

                        logEvent("PartnerResourceEdited", evtData);
                        props.showSnackbar("Partner Resource Updated", "success");
                        props.handleClose();
                    })
                    .catch(() => {
                        props.showSnackbar("Partner Resource Update Failed", "error");
                        props.handleClose();
                    });
            }
            if (!props.resource?.isAdmin && isAdmin) {
                addAdmin(adminPayload)
                    .unwrap()
                    .then((result) => {
                        const evtData: AccessEventPartnerIndividualDetails = {
                            email: adminPayload.emailAddress,
                            partner: selectedPartner.option,
                            imKey: result.imKey.toString(),
                        };

                        logEvent("PartnerResourceAdded", evtData);
                        props.showSnackbar("Partner Resource Updated", "success");
                        props.handleClose();
                    })
                    .catch(() => {
                        props.showSnackbar("Partner Resource Update Failed", "error");
                        props.handleClose();
                    });
            }
            return;
        }

        if (props.resource?.isAdmin) {
            if (props.resource?.firstName || props.resource?.lastName || props.resource?.active) {
                updateAdmin(adminPayload)
                    .unwrap()
                    .then((result) => {
                        const evtData: AccessEventPartnerIndividualDetails = {
                            email: adminPayload.emailAddress,
                            partner: selectedPartner.option,
                            imKey: result.imKey.toString(),
                        };

                        logEvent("PartnerResourceEdited", evtData);
                        props.showSnackbar("Partner Resource Updated", "success");
                        props.handleClose();
                    })
                    .catch(() => {
                        props.showSnackbar("Partner Resource Update Failed", "error");
                        props.handleClose();
                    });
            }
            if (isCoach) {
                addCoach(coachPayload)
                    .unwrap()
                    .then((result) => {
                        const evtData: AccessEventPartnerIndividualDetails = {
                            email: coachPayload.emailAddress,
                            partner: selectedPartner.option,
                            imKey: result.imKey.toString(),
                        };

                        logEvent("PartnerResourceAdded", evtData);
                        props.showSnackbar("Partner Resource Updated", "success");
                        props.handleClose();
                    })
                    .catch(() => {
                        props.showSnackbar("Partner Resource Update Failed", "error");
                        props.handleClose();
                    });
            }
        }
    };

    const saveResource = () => {
        if (isNew) {
            addResource();
        } else {
            updateResource();
        }
    };

    const partnerChange = (evt: SelectChangeEvent) => {
        let newopt: { val: string; option: string } = {
            val: evt.target.value as string,
            option: getOptionFromVal(evt.target.value as string),
        };
        setSelectedPartner(newopt);
    };

    const getOptionFromVal = (val: string): string => {
        if (val == null) return "No Partner Selected";

        let opt = partnerOptions?.find((o) => o.val === val);
        if (opt == null) return "No Partner Selected";

        return opt.option;
    };

    const shouldAllowSave = (): boolean => {
        if (!anyChanges()) return false;

        if (!isNew) return true;

        return (
            (isCoach || isAdmin) &&
            selectedPartner != null &&
            email !== "" &&
            firstName !== "" &&
            lastName !== ""
        );
    };

    return (
        <CclDrawer
            title={title}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="edit-partner-resource"
            aria-describedby="edit-partner-resource-description"
        >
            <Box>
                <Stack spacing={3}>
                    {partnerGroups != null && isNew && props.userPartnerKey == null && (
                        <FormControl size="small" fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="select-partner-label">Select Partner</InputLabel>
                            <Select
                                labelId="select-partner-label"
                                id="select-partner"
                                value={selectedPartner?.val ?? ""}
                                renderValue={(v) => {
                                    return getOptionFromVal(v);
                                }}
                                label="Select Partner"
                                onChange={partnerChange}
                                disabled={!isNew || props.userPartnerKey != null}
                            >
                                {partnerOptions.map((opt) => (
                                    <MenuItem
                                        key={opt.val}
                                        selected={opt.val === selectedPartner?.val}
                                        value={opt.val}
                                    >
                                        {opt.option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {(!isNew || props.userPartnerKey != null) && (
                        <TextField
                            size="small"
                            value={selectedPartner?.option}
                            sx={{ width: 1, maxHeight: "57px", mt: 2 }}
                            id="outlined-adornment-partner"
                            label="Partner"
                            variant="outlined"
                            disabled={true}
                        />
                    )}
                    <TextField
                        size="small"
                        value={firstName}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="outlined-adornment-first-name"
                        label="First Name"
                        variant="outlined"
                        onChange={(e) => {
                            e.preventDefault();
                            setFirstName(e.target.value);
                        }}
                    />
                    <TextField
                        size="small"
                        value={lastName}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="outlined-adornment-last-name"
                        label="Last Name"
                        variant="outlined"
                        onChange={(e) => {
                            e.preventDefault();
                            setLastName(e.target.value);
                        }}
                    />
                    <TextField
                        size="small"
                        value={email}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="outlined-adornment-email"
                        label="Email"
                        variant="outlined"
                        disabled={!isNew}
                        onChange={(e) => {
                            e.preventDefault();
                            setEmail(e.target.value);
                        }}
                        error={emailInputError !== ""}
                        helperText={emailInputError}
                    />
                    <FormGroup>
                        <FormControlLabel
                            disabled={props.resource?.isCoach ?? false}
                            label="Is Coach"
                            control={
                                <Switch
                                    size="small"
                                    checked={isCoach}
                                    onChange={(evt) => setIsCoach(evt.target.checked)}
                                />
                            }
                            sx={{ ml: 0 }}
                        />
                        {!isNew && props.resource?.isCoach && (
                            <FormControlLabel
                                label="Active Coach"
                                control={
                                    <Switch
                                        size="small"
                                        checked={isActive}
                                        onChange={(evt) => setIsActive(evt.target.checked)}
                                    />
                                }
                                sx={{ ml: 0, mt: 3 }}
                            />
                        )}
                        <FormControlLabel
                            disabled={props.resource?.isAdmin ?? false}
                            label="Is Admin"
                            control={
                                <Switch
                                    size="small"
                                    checked={isAdmin}
                                    onChange={(evt) => setIsAdmin(evt.target.checked)}
                                />
                            }
                            sx={{ ml: 0, mt: 3 }}
                        />
                    </FormGroup>
                </Stack>
            </Box>

            <CclDrawerActionBar>
                <CclButton
                    id="cancel-btn"
                    onClick={props.handleClose}
                    disabled={
                        addAdminIsLoading ||
                        updateAdminIsLoading ||
                        addCoachIsLoading ||
                        updateCoachIsLoading
                    }
                    mode={"secondary"}
                >
                    Cancel
                </CclButton>
                <CclLoadingButton
                    id="save-btn"
                    onClick={saveResource}
                    disabled={!shouldAllowSave()}
                    loading={
                        addAdminIsLoading ||
                        updateAdminIsLoading ||
                        addCoachIsLoading ||
                        updateCoachIsLoading
                    }
                    mode={"primary"}
                >
                    Save
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default EditPartnerResourceDrawer;
