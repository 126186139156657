import * as React from "react";
import { useCallback, useState } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import {
    useGetCclParticipantsByDateMutation,
    useLazyGetCclParticipantsByEmailQuery,
} from "../../services/cclTokenedEnterpriseParticipantApi";
import RegistrationsDataGridPro from "./registrationList";
import { useDispatch, useSelector } from "react-redux";
import {
    RegistrationListConfigState,
    updateRegistrationSearchParams,
} from "../../app/slices/registrationListConfigSlice";
import { getDateStringAdjusted } from "../../utilities/dateHelpers";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import { Participant } from "../../services/types/enterpriseParticipantApiTypes";

export const Registrations: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.registrationListConfig);
    const [localConfig, setLocalConfig] = useState<RegistrationListConfigState>({ ...config });
    const [registrations, setRegistrations] = useState<Participant[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [runSearch] = useGetCclParticipantsByDateMutation();
    const [runEmailSearch] = useLazyGetCclParticipantsByEmailQuery();

    const executeSearch = useCallback(
        (searchConfig: RegistrationListConfigState) => {
            if (
                searchConfig.IsDateSearchActive &&
                searchConfig.StartDate !== "" &&
                searchConfig.EndDate !== ""
            ) {
                runSearch({
                    start: searchConfig.StartDate,
                    end: searchConfig.EndDate,
                })
                    .unwrap()
                    .then((data) => {
                        setIsLoading(false);
                        setRegistrations([...data]);
                    })
                    .catch((err) => {
                        setIsError(true);
                        setIsLoading(false);
                        setError(err.error.message);
                    });
            } else if (!searchConfig.IsDateSearchActive && searchConfig.EmailSearch !== "") {
                runEmailSearch(searchConfig.EmailSearch)
                    .unwrap()
                    .then((data) => {
                        setIsLoading(false);
                        setRegistrations([...data]);
                    })
                    .catch((err) => {
                        setIsError(true);
                        setIsLoading(false);
                        setError(err.error.message);
                    });
            }
            setLocalConfig({ ...searchConfig });
            dispatch(updateRegistrationSearchParams(searchConfig));
        },
        [runSearch, runEmailSearch, dispatch]
    );

    React.useEffect(() => {
        executeSearch(localConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeSearch]);

    const getRegistrationsByDate = async (startDt: Date | null, endDt: Date | null) => {
        if (startDt == null || endDt == null) {
            return;
        }
        setIsLoading(true);
        setIsError(false);
        executeSearch({
            StartDate: startDt.toISOString(),
            EndDate: endDt.toISOString(),
            EmailSearch: "",
            IsDateSearchActive: true,
        });
    };

    const getRegistrationsByEmail = async (email?: string) => {
        if (email == null || email?.trim() === "") {
            return;
        }
        setIsLoading(true);
        setIsError(false);
        executeSearch({
            StartDate: getDateStringAdjusted(-2),
            EndDate: getDateStringAdjusted(2),
            EmailSearch: email.trim(),
            IsDateSearchActive: false,
        });
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Registrations
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (isError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <span>{`Error Loading Registrations: ${error}`}</span>
            </StandardLayout>
        );
    }

    return (
        <React.Fragment>
            <StandardLayout breadcrumbs={breadcrumbs}>
                {isError ? (
                    <span>{`Error Loading Registrations: ${error}`}</span>
                ) : (
                    <Stack height={1} width={1}>
                        <Card
                            sx={{
                                height: 1,
                                width: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                                <Box sx={{ flex: "1", paddingRight: 1 }}>
                                    <CclTextSearchBar
                                        initialSearchTerm={localConfig.EmailSearch}
                                        searchLabel="Enter Participant Email"
                                        executeSearch={getRegistrationsByEmail}
                                        removeLeftPadding={true}
                                    />
                                </Box>
                                <Box sx={{ flex: "1", paddingLeft: 1 }}>
                                    <CclDateSearchBar
                                        initialStartDate={
                                            localConfig.IsDateSearchActive
                                                ? new Date(localConfig.StartDate)
                                                : null
                                        }
                                        initialEndDate={
                                            localConfig.IsDateSearchActive
                                                ? new Date(localConfig.EndDate)
                                                : null
                                        }
                                        startDateLabel="Earliest Start Date"
                                        endDateLabel="Latest Start Date"
                                        executeSearch={getRegistrationsByDate}
                                        allowNullDates={false}
                                    />
                                </Box>
                            </Box>
                            {
                                /* this is our initial state where nothing is in the search bar. Should only happen before any search is done */
                                !localConfig.IsDateSearchActive &&
                                localConfig.EmailSearch === "" ? (
                                    <CclSearchDefault
                                        line1="Start searching for Registrations"
                                        line2="Enter Participant Email or enter a Date Range and click Search"
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: 1,
                                            height: 1,
                                            marginTop: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <RegistrationsDataGridPro
                                            loading={isLoading}
                                            registrations={registrations ?? []}
                                            showResultBar={false}
                                            resultBarText=""
                                        />
                                    </Box>
                                )
                            }
                        </Card>
                    </Stack>
                )}
            </StandardLayout>
        </React.Fragment>
    );
};

export default Registrations;
