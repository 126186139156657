import { GridFooterContainer, GridRowCount, GridToolbarExport } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

interface ParticipantDataGridFooterProps {
    apiRef: React.MutableRefObject<GridApiPro>;
    projectCode: string;
}

const ParticipantDataGridFooter: React.FC<ParticipantDataGridFooterProps> = (props) => {
    const { logEvent } = useLogAccessEvent();

    const exportClicked = () => {
        const evtData: AccessEventSessionDetails = {
            projectCode: props.projectCode,
        };
        logEvent("ParticipantDataExported", evtData);
    };

    return (
        <GridFooterContainer>
            <GridToolbarExport
                onClick={() => exportClicked()}
                sx={{
                    "&:hover": { border: "none", background: "#EAEFF4" },
                    border: "none",
                    borderRadius: "100px",
                    padding: "5px",
                    margin: 1,
                }}
            />
            {props.apiRef && (
                <GridRowCount
                    rowCount={props.apiRef.current.getRowsCount()}
                    visibleRowCount={props.apiRef.current.getRowsCount()}
                />
            )}
        </GridFooterContainer>
    );
};

export default ParticipantDataGridFooter;
