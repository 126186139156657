import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
    Participant,
    SetParticipantAttendancePayload,
    SetSessionAttendancePayload,
} from "../../../../services/types/enterpriseParticipantApiTypes";
import { AlertColor, Switch, Typography } from "@mui/material";
import {
    useSetParticipantAttendanceMutation,
    useSetSessionAttendanceMutation,
} from "../../../../services/cclTokenedEnterpriseParticipantApi";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclPersistentDataGrid from "../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { updateSessionsParticipantsGridState } from "../../../../app/slices/sessionParticipantTableStateSlice";
import { useSelector } from "react-redux";
import CclCommonLink from "../../../../components/common/cclCommonLink";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

export interface SessionParticipantListDataGridProProps {
    participants: Participant[] | undefined;
    showAttendance: boolean;
    sessionId: string;
    readOnly?: boolean;
}

export const SessionParticipantListDataGridPro: React.FC<SessionParticipantListDataGridProProps> = (
    props
) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [allAttended, setAllAttended] = React.useState<boolean>(false);
    const [setPaxAttendance, { isLoading: setPaxIsLoading }] =
        useSetParticipantAttendanceMutation();
    const [setSessionAttendance, { isLoading: setSessionIsLoading }] =
        useSetSessionAttendanceMutation();
    const { logEvent } = useLogAccessEvent();
    const [exportFileName, setExportFileName] = React.useState<string>("ParticipantList.csv");
    const [sessionCode, setSessionCode] = React.useState<string>("");
    const { data: sessionDetail } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });

    React.useEffect(() => {
        if (sessionDetail == null) return;
        setSessionCode(sessionDetail.sessionCode);
    }, [sessionDetail]);

    React.useEffect(() => {
        if (props.participants != null && props.participants.length > 0) {
            const numAttended = props.participants.filter((p) => p.attended).length;
            setAllAttended(numAttended === props.participants.length);
            var code = props.participants[0].projectCode;
            setExportFileName(`${code}-ParticipantList.csv`);
        }
    }, [props.participants]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleAttendanceChange = (pax: Participant) => {
        const payload: SetParticipantAttendancePayload = {
            esiKey: pax.esiKey,
            esKey: +props.sessionId,
            payload: {
                attended: !pax.attended,
            },
        };
        setPaxAttendance(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: sessionCode,
                };

                logEvent("ParticipantAttendanceSaved", evtData);
                ShowSnackbarElement("Participant Attendance Udpated", "success");
            })
            .catch(() => {
                ShowSnackbarElement("Participant Attendance Udpate Failed", "error");
            });
    };

    const handleAttendanceChangeForAll = () => {
        const payload: SetSessionAttendancePayload = {
            esKey: +props.sessionId,
            payload: {
                attended: true,
            },
        };
        setSessionAttendance(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: sessionCode,
                };

                logEvent("ParticipantAttendanceSaved", evtData);
                ShowSnackbarElement("Participant Attendance Udpated", "success");
            })
            .catch(() => {
                ShowSnackbarElement("Participant Attendance Udpate Failed", "error");
            });
    };

    function getFullName(params: any) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const COLUMNS = [
        {
            field: "attendance",
            type: "string",
            headerName: "Attendance",
            renderCell: (params: GridRenderCellParams) =>
                props.readOnly ? (
                    <Typography>{params.row["attended"] ? "Attended" : "Not Attended"}</Typography>
                ) : (
                    <Switch
                        size="small"
                        color="primary"
                        checked={params.row["attended"]}
                        onChange={() => handleAttendanceChange(params.row)}
                        inputProps={{ "aria-label": "mark-attendance" }}
                    />
                ),
            sortable: false,
            disableExport: true,
            width: 95,
        },
        {
            field: "projectCode",
            headerName: "Event Code",
        },
        {
            field: "imKey",
            headerName: "IM Key",
        },
        {
            field: "esiKey",
            headerName: "ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/registration/${params.value}`}
                />
            ),
            width: 100,
        },
        {
            field: "firstName",
            headerName: "First Name",
        },
        {
            field: "lastName",
            headerName: "Last Name",
        },
        {
            field: "name",
            headerName: "Participant",
            type: "string",
            flex: 0.75,
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/registration/${params.row.esiKey}`}
                />
            ),
        },
        {
            field: "emailAddress",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1,
        },
        {
            field: "telephone",
            headerName: "Phone Number",
            flex: 0.5,
        },
        {
            field: "organization",
            headerName: "Organization",
            flex: 0.8,
        },
        {
            field: "title",
            headerName: "Title",
            flex: 0.6,
        },
        {
            field: "address1",
            headerName: "Address 1",
        },
        {
            field: "address2",
            headerName: "Address 2",
        },
        {
            field: "city",
            headerName: "City",
        },
        {
            field: "state",
            headerName: "State",
        },
        {
            field: "postalCode",
            headerName: "Postal Code",
        },
        {
            field: "country",
            headerName: "Country",
        },
        {
            field: "ceRegistrationId",
            headerName: "CE Registration ID",
        },
    ];

    const stateSelector = useSelector((state: any) => state.sessionParticipantTableState);
    const visibilityModel = {
        projectCode: false,
        imKey: false,
        esiKey: false,
        name: true,
        firstName: false,
        lastName: false,
        emailAddress: true,
        attendance: props.showAttendance,
        organization: true,
        title: true,
        telephone: true,
        address1: false,
        address2: false,
        city: false,
        state: false,
        postalCode: false,
        country: false,
        ceRegistrationId: false,
    };

    // visibility model setting wasn't working as expected so for now eliminating the column with a filter if needed
    const actualColumns = props.showAttendance
        ? COLUMNS
        : COLUMNS.filter((c) => c.field !== "attendance");

    return (
        <React.Fragment>
            {showSnackbar && (
                <CclAlertSnackbar
                    open={true}
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <CclPersistentDataGrid
                columns={addCustomDataGridFilters(actualColumns as GridColumns<any>)}
                data={props.participants == null ? [] : props.participants}
                loading={false}
                stateSelector={stateSelector}
                userPreferenceId={0}
                getRowIdFn={(row: Participant) => row.esiKey}
                actionCreator={updateSessionsParticipantsGridState}
                hideSettingsButtons={true}
                exportName={exportFileName}
                miscButtons={
                    props.showAttendance && !props.readOnly
                        ? [
                              {
                                  id: "session-mark-all-attended",
                                  caption: "Mark All Attended",
                                  onClick: handleAttendanceChangeForAll,
                                  returnSelected: true,
                                  disable: allAttended,
                                  restricted: false,
                                  loadingBtn: true,
                                  isLoading: setPaxIsLoading || setSessionIsLoading,
                              },
                          ]
                        : []
                }
                initState={JSON.stringify({
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }],
                    },
                    columns: {
                        columnVisibilityModel: visibilityModel,
                    },
                })}
                columnVisibility={visibilityModel}
                hideFooter={false}
            />
        </React.Fragment>
    );
};

export default SessionParticipantListDataGridPro;
