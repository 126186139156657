import * as React from "react";
import { Alert, AlertColor, Card, CardContent, CardHeader } from "@mui/material";
import { GrandCentralConnection } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    useDeleteCclApplicationConnectionMutation,
    useUpdateCclApplicationConnectionMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import UpdateLoginApplicationConnectionDialog from "./updateLoginApplicationConnectionDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";
import { useGridApiRef, GridRowId, DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import CclDataGridCustomButtonToolbar from "../../../../components/common/cclDataGridCustomButtonToolbar/cclDataGridCustomButtonToolbar";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

export interface GcConnectionCardProps {
    systemId: string;
    systemName: string;
    applicationId: string;
    gcConnections: GrandCentralConnection[];
    readonly: boolean;
}

export const GcConnectionCard: React.FC<GcConnectionCardProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const { logEvent } = useLogAccessEvent();
    const [showUpdateConnectionDialog, setShowUpdateConnectionDialog] =
        React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [selectedConnection, setSelectedConnection] = React.useState<GrandCentralConnection>({
        identityProviderConnectionId: "",
        identityProviderId: "",
        connectionId: "",
        name: "",
    });
    const [deleteConnection, { isLoading: deleteLoading }] =
        useDeleteCclApplicationConnectionMutation();
    const [updateConnection, { isLoading: updateLoading }] =
        useUpdateCclApplicationConnectionMutation();
    const apiRef = useGridApiRef();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const onUpdateConnection = (conn: GrandCentralConnection) => {
        setSelectedConnection(conn);
        setShowUpdateConnectionDialog(true);
    };

    const onConnectionUpdate = (conn: GrandCentralConnection) => {
        updateConnection({
            applicationId: props.applicationId,
            loginApplicationConnectionId: conn.connectionId,
            identityProviderConnectionId: conn.identityProviderConnectionId,
        })
            .unwrap()
            .then((res) => {
                const evtData: AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                if (!res) {
                    ShowSnackbarElement("Update Connection Failed", "error");
                } else {
                    ShowSnackbarElement("Connection Updated", "success");
                }
            })
            .catch((err) => {
                ShowSnackbarElement("Update Connection Failed", "error");
            });
    };

    const onDeleteConnection = (conn: GrandCentralConnection) => {
        deleteConnection({
            applicationId: props.applicationId,
            loginApplicationConnectionId: selectedConnection.connectionId,
            identityProviderConnectionId: selectedConnection.identityProviderConnectionId,
        })
            .unwrap()
            .then((res) => {
                const evtData: AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                console.log("Delete Application result: ", res ? "Success" : "Failed");
                if (!res) {
                    ShowSnackbarElement("Delete Connection Failed", "error");
                } else {
                    ShowSnackbarElement("Connection Deleted", "success");
                }
            })
            .catch((err) => {
                ShowSnackbarElement("Delete Connection Failed", "error");
            });
    };

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (!rows) return;
        let rowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        let rowArray: any[] = [];
        for (let row of Array.from(rowsMap.values())) {
            rowArray.push(row);
        }
        setSelectedRows(rowArray);
    };

    const handleBtnClick = (btnId: string) => {
        switch (btnId) {
            case "edit-connection-button":
                onUpdateConnection(selectedRows[0]);
                break;
            case "delete-connection-button":
                onDeleteConnection(selectedRows[0]);
        }
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "Name",
            type: "string",
            flex: 1,
        },
        {
            field: "connectionId",
            headerName: "ID",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <UpdateLoginApplicationConnectionDialog
                open={showUpdateConnectionDialog}
                onClose={() => setShowUpdateConnectionDialog(false)}
                conn={selectedConnection}
                onSave={onConnectionUpdate}
            />
            <Card variant="outlined" sx={{ height: 1 }}>
                <CardHeader title="Grand Central Connections" />
                <CardContent sx={{ padding: "5px" }}>
                    {props.gcConnections && props.gcConnections?.length > 0 ? (
                        <DataGridPro
                            apiRef={apiRef}
                            getRowId={(row) => row.connectionId}
                            rows={props.gcConnections}
                            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                            components={{ Toolbar: CclDataGridCustomButtonToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showSaveState: false,
                                    hideSettingsButtons: true,
                                    miscButtons: [
                                        {
                                            id: "edit-connection-button",
                                            caption: "Edit",
                                            loadingBtn: true,
                                            isLoading: updateLoading,
                                            disable: selectedRows.length !== 1,
                                        },
                                        {
                                            id: "delete-connection-button",
                                            caption: "Delete",
                                            loadingBtn: true,
                                            isLoading: deleteLoading,
                                            disable: selectedRows.length !== 1,
                                        },
                                    ],
                                    btnClick: (btnId: string) => handleBtnClick(btnId),
                                    anySelected: null,
                                },
                            }}
                            checkboxSelection={true}
                            onSelectionModelChange={(rows: any[] | undefined) =>
                                updateSelectedRows(rows)
                            }
                            autoHeight={true}
                        />
                    ) : (
                        <Alert severity="info">No Grand Central Connections Found</Alert>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default GcConnectionCard;
