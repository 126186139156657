import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!\
import timeGridPlugin from "@fullcalendar/timegrid";
import { useLazyGetCalendarEventsQuery } from "../../services/cclTokenedSessionApi";
import { DatesSetArg, EventClickArg, EventInput } from "@fullcalendar/core";
import { Alert, AlertTitle, Box } from "@mui/material";
import ComponentLoader from "../../components/common/componentLoader";
import { SessionCalendarEvent } from "../../services/types/sessionDetailTypes";
import PageLoader from "../../components/common/pageLoader";
import CalendarSessionDetailDrawer from "./calendarSessionDetailDrawer";

export interface ScoringCalendarProps {}

export const ScoringCalendar: React.FC<ScoringCalendarProps> = () => {
    var now = new Date();
    const [startDate, setStartDate] = React.useState<Date>(
        new Date(now.getFullYear(), now.getMonth(), 1)
    );
    const [endDate, setEndDate] = React.useState<Date>(
        new Date(now.getFullYear(), now.getMonth() + 1, 1)
    );
    const [calendarEvents, setCalendarEvents] = React.useState<EventInput[]>([]);
    const [selectedEvent, setSelectedEvent] = React.useState<SessionCalendarEvent | null>(null);
    const [showEventDetail, setShowEventDetail] = React.useState<boolean>(false);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);

    const [getEvents, { data, isSuccess }] = useLazyGetCalendarEventsQuery();

    React.useEffect(() => {
        setShowSpinner(true);
        getEvents({
            start: startDate.toISOString().substring(0, 10),
            end: endDate.toISOString().substring(0, 10),
        })
            .unwrap()
            .then((rsp: SessionCalendarEvent[]) => {
                let newEvts = rsp
                    .filter((x: SessionCalendarEvent) => x.scoreDate != null)
                    .map((x: SessionCalendarEvent) => {
                        const e: EventInput = {};
                        e.id = x.programId.toString();
                        e.title = `${x.programCode} - ${new Date(x.startDate).toLocaleString(
                            "default",
                            {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }
                        )} - ${x.campusAbbreviation} - ${x.title}`;
                        e.start = x.scoreDate;
                        e.backgroundColor = "#2F5F95";
                        return e;
                    });
                setCalendarEvents([...newEvts]);
                setShowSpinner(false);
            })
            .catch(() => {
                setShowSpinner(false);
            });
    }, [startDate, endDate, getEvents]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    function eventClickHandler(info: EventClickArg) {
        const sessionEvent = data?.find((e) => e.programId === +info.event.id);
        if (sessionEvent != null) {
            setShowEventDetail(true);
            setSelectedEvent(sessionEvent);
        }
    }

    function changeDateHandler(info: DatesSetArg) {
        if (info.start < startDate || info.end > endDate) {
            setStartDate(info.start);
            setEndDate(info.end);
        }
    }

    if (data == null) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (isSuccess && data != null) {
        return (
            <Box sx={{ width: 1, height: 1 }}>
                {selectedEvent != null && showEventDetail ? (
                    <CalendarSessionDetailDrawer
                        open={showEventDetail}
                        evt={selectedEvent}
                        onClose={() => setShowEventDetail(false)}
                    />
                ) : null}
                {showSpinner && <PageLoader msg={""} />}
                <FullCalendar
                    height={1000}
                    aspectRatio={1}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    headerToolbar={{
                        start: "today prev next",
                        center: "title",
                        end: "dayGridMonth dayGridWeek dayGridDay",
                    }}
                    events={calendarEvents}
                    eventDisplay="block"
                    eventClick={eventClickHandler}
                    displayEventTime={false}
                    showNonCurrentDates={false}
                    datesSet={changeDateHandler}
                    buttonText={{
                        today: "Today",
                        month: "Month",
                        week: "Week",
                        day: "Day",
                        list: "List",
                    }}
                />
            </Box>
        );
    }

    return showErrorAlert(`Error retrieving calendar`);
};

export default ScoringCalendar;
