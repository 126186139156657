import * as React from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../services/cclTokenedEnterpriseParticipantApi";
import {
    AddRaterRequest,
    Individual,
    RaterDetails,
} from "../../../../services/types/assessmentAPITypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import {
    useLazyGetAssessmentByParticipantQuery,
    useSearchRatersMutation,
    useLazyGetRaterTypesByInstrumentKeyQuery,
    useLazyGetLanguagesByInstrumentIdQuery,
} from "../../../../services/cclTokenedEnterpriseRaterApi";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import { validateEmail } from "../../../../utilities/generalUtilities";

export type AddRaterDrawerProps = {
    open: boolean;
    onCancel: () => void;
    onAdd: (request: AddRaterRequest) => void;
    esKey: number;
    raterList: RaterDetails[];
    registration?: Participant;
    isAdding: boolean;
};

const AddRaterDrawer: React.FC<AddRaterDrawerProps> = (props) => {
    const [participantName, setParticipantName] = React.useState<string>(
        props.registration?.fullName ?? ""
    );
    const [participantId, setParticipantId] = React.useState<number>(
        props.registration?.esiKey ?? 0
    );
    const [selectedAssessment, setSelectedAssessment] = React.useState<string>("");
    const [assessmentOptions, setAssessmentOptions] = React.useState<string[]>([]);
    const [selectedInstrumentKey, setSelectedInstrumentKey] = React.useState<number>(0);
    const [newRaterEmail, setNewRaterEmail] = React.useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
    const [existingRaterFound, setExistingRaterFound] = React.useState<boolean>(false);
    const [newRaterFirstName, setNewRaterFirstName] = React.useState<string>("");
    const [newRaterLastName, setNewRaterLastName] = React.useState<string>("");
    const [newRaterSelectedLanguage, setNewRaterSelectedLanguage] = React.useState<string>("");
    const [newRaterSelectedRaterType, setNewRaterSelectedRaterType] = React.useState<string>("");

    // data fetches
    const { data: participantList } = useGetCclParticipantsByEventKeyQuery(props.esKey.toString(), {
        skip: props.esKey === 0 || props.registration != null,
    });
    const [getAssessments, { data: assessmentList }] = useLazyGetAssessmentByParticipantQuery();
    const [getRaterTypesForAssessment, { data: raterTypeList }] =
        useLazyGetRaterTypesByInstrumentKeyQuery();
    const [getLanguagesForAssessment, { data: assessmentLanguageList }] =
        useLazyGetLanguagesByInstrumentIdQuery();
    const [searchForExistingRater, { isLoading }] = useSearchRatersMutation();

    // effects
    React.useEffect(() => {
        if (participantName) {
            var esiKey = props.registration
                ? props.registration?.esiKey
                : participantList?.find((p) => p.fullName === participantName)?.esiKey;
            if (esiKey) {
                setParticipantId(esiKey);
                getAssessments(esiKey);
            } else {
                setParticipantId(0);
            }
        }
        //eslint-disable-next-line
    }, [participantList, participantName]);

    React.useEffect(() => {
        if (selectedAssessment !== "") {
            var instrumentKey = assessmentList?.filter(
                (a) => a.instrumentName === selectedAssessment
            )[0].instrumentId;
            if (instrumentKey) {
                setSelectedInstrumentKey(instrumentKey);
                getRaterTypesForAssessment(instrumentKey);
                getLanguagesForAssessment({ Instrument: instrumentKey, PreferredLanguageId: 1 });
                return;
            }
        }
        setSelectedInstrumentKey(0);
        const opts =
            assessmentList
                ?.filter((a) => a.instrumentTypeId === 2 || a.instrumentTypeId === 4)
                .map((a) => a.instrumentName) ?? [];
        setAssessmentOptions(opts);
        //eslint-disable-next-line
    }, [selectedAssessment, assessmentList]);

    // functions
    const validateNewEmail = (searchEmail: string) => {
        if (!validateEmail(searchEmail)) {
            setEmailErrorMessage("Enter a valid email address");
        } else {
            var isExist = props.raterList.filter(
                (r) =>
                    r.raterEmail === searchEmail &&
                    parseInt(r.participant_ESI_Key) === participantId &&
                    r.instrumentKey === selectedInstrumentKey
            );
            if (isExist.length > 0) {
                setEmailErrorMessage("Enter a valid email address");
            } else {
                setEmailErrorMessage("");
                searchForExistingRater({ email: searchEmail })
                    .unwrap()
                    .then((existingRater: Individual) => {
                        if (existingRater) {
                            setExistingRaterFound(true);
                            setNewRaterFirstName(existingRater.firstName);
                            setNewRaterLastName(existingRater.lastName);
                            setNewRaterSelectedLanguage(
                                assessmentLanguageList?.find(
                                    (a) =>
                                        a.languageId === parseInt(existingRater.preferredLanguageId)
                                )?.name ?? "English"
                            );
                        } else {
                            setExistingRaterFound(false);
                            setNewRaterFirstName("");
                            setNewRaterLastName("");
                            setNewRaterSelectedLanguage("");
                        }
                    });
            }
        }
    };

    const enableAdd = (): boolean => {
        // make sure participant is selected
        if (participantName === "" || participantId === 0) {
            return false;
        }
        // make sure assessment is selected
        if (selectedAssessment === "" || selectedInstrumentKey === 0) {
            return false;
        }

        // check new rater info
        if (
            newRaterEmail === "" ||
            newRaterFirstName === "" ||
            newRaterLastName === "" ||
            newRaterSelectedRaterType === ""
        ) {
            return false;
        }
        return true;
    };

    const handleAdd = () => {
        const request: AddRaterRequest = {
            participantId: participantId,
            email: newRaterEmail,
            firstName: newRaterEmail,
            lastName: newRaterLastName,
            instrumentId: selectedInstrumentKey,
            languageId:
                assessmentLanguageList?.find((a) => a.name === newRaterSelectedLanguage)
                    ?.languageId ?? 1,
            raterTypeId:
                raterTypeList?.find((r) => r.name === newRaterSelectedRaterType)?.raterTypeId ?? 0,
        };
        props.onAdd(request);
    };

    return (
        <CclDrawer
            open={props.open}
            title={props.registration == null ? "Add Rater" : `Add Rater for ${participantName}`}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onCancel}
        >
            <Box>
                {props.registration == null ? (
                    <Autocomplete
                        id="Participant-DropDown"
                        options={
                            participantList
                                ?.map((option) => option.fullName)
                                .sort((a, b) => {
                                    return a.localeCompare(b);
                                }) ?? []
                        }
                        value={participantName}
                        onChange={(e: any, newValue: string | null) => {
                            setParticipantName(newValue ?? "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Participant List"
                                label="Participant List"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        )}
                    />
                ) : null}
                <Autocomplete
                    disabled={participantName === ""}
                    id="Assessment-DropDown"
                    options={assessmentOptions}
                    value={selectedAssessment}
                    onChange={(e: any, newValue: string | null) => {
                        setSelectedAssessment(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Assessment List"
                            label="Assessment List"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
                <TextField
                    disabled={selectedAssessment === ""}
                    error={emailErrorMessage !== ""}
                    helperText={emailErrorMessage}
                    onChange={(e: any) => {
                        e.preventDefault();
                        validateNewEmail(e.target.value);
                        setNewRaterEmail(e.target.value);
                    }}
                    value={newRaterEmail}
                    id="txtEmail"
                    label="Email"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    disabled={selectedAssessment === "" || existingRaterFound}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setNewRaterFirstName(e.target.value);
                    }}
                    value={newRaterFirstName}
                    id="txtFirstName"
                    label="First Name"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    disabled={selectedAssessment === "" || existingRaterFound}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setNewRaterLastName(e.target.value);
                    }}
                    value={newRaterLastName}
                    id="txtLastName"
                    label="Last Name"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <Autocomplete
                    disabled={selectedAssessment === "" || existingRaterFound}
                    id="Language-DropDown"
                    options={assessmentLanguageList?.map((option) => option.name) ?? []}
                    value={newRaterSelectedLanguage}
                    onChange={(e: any, newValue: string | null) => {
                        setNewRaterSelectedLanguage(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Language"
                            label="Language"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
                <Autocomplete
                    disabled={selectedAssessment === ""}
                    id="Rater-DropDown"
                    options={raterTypeList?.map((option) => option.name) ?? []}
                    value={newRaterSelectedRaterType}
                    onChange={(e: any, newValue: string | null) => {
                        setNewRaterSelectedRaterType(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Rater Type"
                            label="Rater Type"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </Box>

            <CclDrawerActionBar>
                <CclButton id="cancel-btn" onClick={() => props.onCancel()} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclLoadingButton
                    id="ok-btn"
                    disabled={!enableAdd()}
                    onClick={handleAdd}
                    mode={"primary"}
                    loading={props.isAdding || isLoading}
                >
                    Add
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};
export default AddRaterDrawer;
