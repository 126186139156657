import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclPersistentDataGrid from "../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { ApplicationSearchInvitationResponse } from "../../../../services/types/applicationTypes";
import CclCommonLink from "../../../../components/common/cclCommonLink";
import { updateApplicationInvitationsSearchGridState } from "../../../../app/slices/applicationInvitationsSearchListTableStateSlice";

export interface ApplicationInvitationDataGridProps {
    invitations: ApplicationSearchInvitationResponse[];
    isLoading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    editButtonHandler?: (userEmail: string) => void;
}

export const ApplicationInvitationDataGrid: React.FC<ApplicationInvitationDataGridProps> = (
    props
) => {
    const stateSelector = useSelector(
        (state: any) => state.applicationInvitationsSearchListTableState
    );

    const COLUMNS = [
        {
            field: "email",
            headerName: "Recipient",
            valueGetter: (params: any) => {
                return params.row.email;
            },
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 9,
        },
        {
            field: "emailStatus",
            headerName: "Email Status",
            flex: 4,
        },
        {
            field: "active",
            headerName: "Accepted",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params?.value ? "Yes" : "No"}</span>
            ),
            flex: 4,
        },
        {
            field: "completed",
            headerName: "User Accepted",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params?.value ? "Yes" : "No"}</span>
            ),
            flex: 6,
        },
        {
            field: "groups",
            headerName: "Groups",
            valueGetter: (params: any) => {
                return params.row.invitationGroups.length > 0
                    ? params.row.invitationGroups
                          .map((x: { groupname: any }) => x.groupname)
                          .join(", ")
                    : "";
            },
            flex: 8,
        },
        {
            field: "createdUtc",
            headerName: "Date Sent",
            valueGetter: (params: any) => new Date(params.row.createdUtc),
            type: "Date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 7,
        },
        {
            field: "expiresOnUtc",
            headerName: "Expires",
            valueGetter: (params: any) => new Date(params.row.expiresOnUtc),
            type: "Date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 7,
        },
    ];

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.invitations || []}
            loading={props.isLoading || false}
            stateSelector={stateSelector}
            userPreferenceId={20}
            getRowIdFn={(row: ApplicationSearchInvitationResponse) => row.invitationId}
            actionCreator={updateApplicationInvitationsSearchGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "email", sort: "asc" }] },
            })}
            resultBar={false}
            resultBarText={""}
            showCheckboxes={false}
            autoHeight={false}
            hideFooter={false}
        />
    );
};

export default ApplicationInvitationDataGrid;
