import React from "react";
import {
    AlertColor,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../components/common/cclButtons/cclButton";

interface SetAlertDrawerProps {
    open: boolean;
    title: string | null;
    msg: string | null;
    severity: AlertColor | null;
    onSave: (title: string | null, msg: string | null, severity: AlertColor | null) => void;
    onCancel: () => void;
}

const SetAlertDrawer: React.FC<SetAlertDrawerProps> = (props) => {
    const [title, setTitle] = React.useState<string | null>(props.title);
    const [msg, setMsg] = React.useState<string | null>(props.msg);
    const [severity, setSeverity] = React.useState<AlertColor | null>(props.severity);

    const handleSeverityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSeverity = event.target.value;
        setSeverity(newSeverity as AlertColor);
    };

    React.useEffect(() => {
        if (!props.open) {
            setTitle(props.title);
            setMsg(props.msg);
            setSeverity(props.severity);
        }
    }, [props.msg, props.open, props.severity, props.title]);

    return (
        <CclDrawer title={"Set System Alert"} open={props.open} onClose={props.onCancel}>
            <Box>
                <Typography>Leave fields blank to use the default values.</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="alertTitle"
                    label="System Alert Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={title}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        setTitle(evt.target.value)
                    }
                />
                <TextField
                    autoFocus
                    multiline={true}
                    rows={4}
                    margin="dense"
                    id="alertMessage"
                    label="System Alert Message"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={msg}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        setMsg(evt.target.value)
                    }
                />
                <FormControl
                    fullWidth
                    sx={{
                        border: 1,
                        borderColor: "lightgray",
                        borderRadius: 1,
                        padding: 2,
                        paddingLeft: 3,
                        marginTop: 2,
                    }}
                >
                    <FormLabel
                        sx={{ color: "gray", textDecoration: "underline" }}
                        id="system-alert-severity-label"
                    >
                        Select Color
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="system-alert-severity-label"
                        name="system-alert-severity"
                        onChange={handleSeverityChange}
                        value={severity}
                        sx={{ justifyContent: "space-between" }}
                    >
                        <FormControlLabel
                            value="info"
                            control={<Radio color="info" />}
                            label="Info (Default)"
                            sx={{ color: "info.main" }}
                        />
                        <FormControlLabel
                            value="success"
                            control={<Radio color="success" />}
                            label="Success"
                            sx={{ color: "success.main" }}
                        />
                        <FormControlLabel
                            value="warning"
                            control={<Radio color="warning" />}
                            label="Warning"
                            sx={{ color: "warning.main" }}
                        />
                        <FormControlLabel
                            value="error"
                            control={<Radio color="error" />}
                            label="Danger"
                            sx={{ color: "error.main" }}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box display={"flex"} gap={3} sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
                <CclButton mode="secondary" onClick={props.onCancel} sx={{ float: "right" }}>
                    Cancel
                </CclButton>
                <CclButton
                    mode="primary"
                    onClick={() => props.onSave(title, msg, severity)}
                    sx={{ float: "right" }}
                >
                    Save
                </CclButton>
            </Box>
        </CclDrawer>
    );
};

export default SetAlertDrawer;
