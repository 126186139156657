import * as React from "react";
import { TextFieldProps, TextField, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CclButton from "../../components/common/cclButtons/cclButton";

interface CclDateWithTextSearchBarProps {
    initialSearchTerm: string;
    initialStartDate: Date | null;
    initialEndDate: Date | null;
    startDateLabel: string;
    endDateLabel: string;
    searchTermLabel: string;
    executeSearch: (startDate: Date | null, endDate: Date | null, searchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
    allowNullDates?: boolean;
}

const CclDateWithTextSearchBar: React.FC<CclDateWithTextSearchBarProps> = (props) => {
    const [startDate, setStartDate] = React.useState<Date | null>(
        props.initialStartDate ? new Date(props.initialStartDate) : null
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        props.initialEndDate ? new Date(props.initialEndDate) : null
    );
    const [searchTerm, setSearchTerm] = React.useState<string>(props.initialSearchTerm ?? "");
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setStartDate(props.initialStartDate);
        setEndDate(props.initialEndDate);
    }, [props.initialStartDate, props.initialEndDate]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
        flexGrow: 0.75,
    };

    const handleDateSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (!endDateError && !startDateError && endDate != null && startDate != null) {
                evt.preventDefault();
                props.executeSearch(startDate, endDate, searchTerm);
            }
        }
    };

    const setStartSearchError = (error: boolean) => {
        if (error !== startDateError) {
            setStartDateError(error);
            if (props.errorStateChange) props.errorStateChange(error || endDateError);
        }
    };

    const setEndSearchError = (error: boolean) => {
        if (error !== endDateError) {
            setEndDateError(error);
            if (props.errorStateChange) props.errorStateChange(error || startDateError);
        }
    };

    return (
        <Box
            gap={2}
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <DatePicker
                label={props.startDateLabel ?? "Start Date"}
                value={startDate}
                onChange={(newValue: Date | null) => {
                    setStartDate(newValue);
                }}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    return (
                        <TextField
                            {...params}
                            error={startDateError}
                            helperText={startDateError ? "Enter a valid date" : ""}
                            onKeyDown={(e) => handleDateSearchKeyPress(e)}
                            sx={{ ...searchInputSx, flexGrow: 0.75 }}
                        />
                    );
                }}
                onError={(reason, value) => {
                    if (reason) {
                        setStartSearchError(true);
                    } else {
                        setStartSearchError(false);
                    }
                }}
            />
            <DatePicker
                label={props.endDateLabel ?? "End Date"}
                value={endDate}
                onChange={(newValue: Date | null) => {
                    setEndDate(newValue);
                }}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    return (
                        <TextField
                            {...params}
                            error={endDateError}
                            helperText={endDateError ? "Enter a valid date" : ""}
                            onKeyDown={(e) => handleDateSearchKeyPress(e)}
                            sx={{ ...searchInputSx, flexGrow: 0.75 }}
                        />
                    );
                }}
                onError={(reason, value) => {
                    if (reason) {
                        setEndSearchError(true);
                    } else {
                        setEndDateError(false);
                    }
                }}
            />
            <TextField
                value={searchTerm === "*" ? "" : searchTerm}
                sx={{ ...searchInputSx, flexGrow: 1 }}
                id="outlined-adornment-keyword-search"
                label={props.searchTermLabel ?? "Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setSearchTerm(e.target.value);
                }}
                onKeyDown={(e) => handleDateSearchKeyPress(e)}
            />
            <CclButton
                onClick={() => props.executeSearch(startDate, endDate, searchTerm)}
                aria-label="Search By Date"
                sx={{ flexShrink: 0, wordWrap: "nowrap" }}
                disabled={endDateError || startDateError || endDate == null || startDate == null}
                mode={"primary"}
            >
                Search
            </CclButton>
        </Box>
    );
};

export default CclDateWithTextSearchBar;
